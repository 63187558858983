import RecursiveSongView from "./RecursiveSongView";

const MergedSongsTree = ({ 
    songsData, 
    expandedItems, 
    setSelectedSong,
    setExpandedItems,
    setShowUnmergeModal,
    setShowMoveModal,
    searchQuery,
    hideIrrelevantSearchResults,
}) => {
    const toggleExpand = (itemId) => {
        // which songs are expanded
        setExpandedItems((prevExpandedItems) => (
            prevExpandedItems.includes(itemId)
                ? prevExpandedItems.filter((id) => id !== itemId)
                : [...prevExpandedItems, itemId]
        ));
    }

    return (
        <RecursiveSongView
            object={songsData}
            toggleExpand={toggleExpand}
            expandedItems={expandedItems}
            setSelectedSong={setSelectedSong}
            setShowUnmergeModal={setShowUnmergeModal}
            setShowMoveModal={setShowMoveModal}
            searchQuery={searchQuery}
            hideIrrelevantSearchResults={hideIrrelevantSearchResults}
        />
    )
}

export default MergedSongsTree;