import React, { useEffect, useRef, useState } from 'react';
import recordLogo from '../../assets/recordLogo.svg';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink, useHistory } from 'react-router-dom';
import { useStoreContext, initialStoreState } from '../../store/Store.js';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import { BrandButton } from '../CoreComponents/BrandButton';
import { postData } from '../utils/FetchUtils'
import { Avatar, ClickAwayListener, Grow, ListItemText, MenuList, Paper, Popover, Popper } from '@material-ui/core';
import { ArrowDropDown, BarChartOutlined } from '@material-ui/icons';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import AlbumIcon from '@material-ui/icons/Album';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import StorageIcon from '@material-ui/icons/Storage';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import SettingsIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import DescriptionIcon from '@material-ui/icons/Description';
import StyleIcon from '@material-ui/icons/Style';
import TimelineIcon from '@material-ui/icons/Timeline';
import { EthIcon } from '../Portfolio/NftPortfolio';
import MergeTypeIcon from '@material-ui/icons/MergeType.js';
import BusinessIcon from '@material-ui/icons/Business';
import DomainIcon from '@mui/icons-material/Domain';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StatementsSvg from '../../assets/statements.svg';

require("dotenv").config();

const NavItem = withStyles((theme) => ({
  link: {
    width: '100%',
    padding: theme.spacing(2.5, '22px'),
    whiteSpace: 'nowrap',
    position: 'relative',

    '&.active': {
      color: theme.palette.primary.main,
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },

    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    }
  },
}))(({ classes, ...props }) =>
  <Link
    to="/"
    classes={{ root: classes.link }}
    component={NavLink}
    exact={true}
    color="inherit"
    {...props}
  />
)

const useStyles = makeStyles((theme) => ({
  customButtonPlacement: {
    '@supports (-moz-appearance:none)': {
      marginTop: '0.6em',
      marginLeft: '0.3em'
    },
    marginTop: '2.2em',
    marginLeft: '0em',
  },

  root: {
    backgroundColor: '#5B4294',
    color: 'white',
    borderTop: '2px solid #3075B83A',
    paddingTop: '1em',
    paddingBottom: '1em',
    paddingRight: '3em',
    '&:hover': {
      backgroundColor: '#5B4294',
      color: 'white',
      cursor: 'pointer'
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#BDB3D4',
      color: 'white',
      cursor: 'pointer'
    },
    '&.Mui-selected': {
      backgroundColor: '#BDB3D4',
      color: 'white',
      cursor: 'pointer'
    }
  },
  customList: {
    padding: '0px',
    backgroundColor: '#5B4294',
    color: '#81BFED'
  },
  customPaper: {
    marginLeft: '1em',
    marginTop: '3.2em',
    width: 'auto',
  },
  customLabelForButton: {
    color: '#81C1FF',
    textTransform: 'lowercase',
  },
  customLink: {
    borderBottom: '1px solid gray',
    color: '#81BFED',
  },
  colorDefault: {
    color: 'white',
    background: '#FFFFFF3D'
  },
  navBar: {
    background: theme.palette.background.default
  },
  navHeader: {
    display: 'flex',
    color: 'white',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid black',
    height: '90px',
    "& .MuiTypography-root": {
      //fontFamily: 'Roboto Light,Sans-serif',
      letterSpacing: '1.25px',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '18px'
    }
  },
  logo: {
    width: '116px',
    height: '32px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  avatar: {
    marginLeft: '16px',
    '& .MuiAvatar-root': {
      //fontFamily: 'Roboto Light,Sans-serif',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '16px'
    }
  },
  pageContainer: {
    minWidth: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
    overflow: 'auto',
    scrollBehavior: 'smooth', 

    '&::-webkit-scrollbar': {
        height: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.lightGray,
        borderRadius: '2px',
        border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: theme.palette.primary.darkGray,
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.primary.black,
        borderRadius: '2px',
        border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
    },
    
  },
  pageContainerContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
  },
  rootPadding: {
    padding: '2rem'
  },
  userInfo: {
    display: 'flex',
    marginRight: '1.5em',
    alignItems: 'center'
  },
  uploadCsvButton: {
    height: '40px',
    color: 'black'
  },
  navIcon: {
    marginBottom: -6,
    marginRight: 9
  },
  navButtons: {
    display: 'flex',
    gap: theme.spacing(1)
  },
  vertical: {
    height: '40px',
    display: 'inline-block',
    border: '1px solid white',
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5)
  },
  drawerToolbar: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
  selectedNavTypeIcon: {
    color: 'white'
  },
  selectedNavType: {
    fontSize: '25px',
    fontWeight: '400',
    width: '30px',
    height: '67.65'
  },
  drawerListItem: {
    display: 'flex',
    alignItems: 'center',
    width: '240px',
    gap: theme.spacing(1),
  },
  ethIconWidth: {
    width: '24px',
    height: '24px'
  },
  selectedDrawerListItem: {
    background: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  navItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: '14px',
    marginLeft:'60px',
    alignItems: 'center',
    '& a': {
      textAlign: 'center',
      padding: '5px 0px !important'
    }
  },
  navItemDefault:{
    width: '190px'
  },
  navItemSmall:{
    width:'100px'
  },
  navItemBig:{
    width: '180px'
  },
  navMedium:{
    width:'160px'
  },
  analyze: {
    width: '200px',
    margin: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    '&.active': {
      '& .graphIcon': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: 'black'
      }
    },
    '& .graphIcon': {
      margin: '0',
      background: theme.palette.text.primary,
      color: 'black',
      width: '24px',
      height: '24px',
      borderRadius: '3px'
    },
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
      '& .graphIcon': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      }
    }
  },
  navItemWithOptions: {
    width: '100%',
    padding: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    position: 'relative',

    '&.active': {
      color: theme.palette.primary.main,
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },

    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    }
  },
  moreOptionsIcon: {
    marginBottom: -8,
    fontSize: '18px',
  },
  popperImage: {
    marginRight: '10px',
  },
  reportingPopper: {
    zIndex: 1, 
    border: `1px solid ${theme.palette.primary.border}`, 
    borderRadius: '6px',
    marginTop: '10px',
  },
}));

function Navigation({ children }) {
  const styles = useStyles();
  const history = useHistory();
  const [state, setState] = useStoreContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(null);
  const anchorRef = useRef(null);

  useEffect(() => {
    setIsAdmin(state.user && state.user.userType === 1);
  }, [state.user]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (event) => {
    setOpenDrawer(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenDrawer(null);
  };

  const handleLogout = async () => {
    await postData(process.env.REACT_APP_SERVER_HOST + "/api/user/logout");
    setState(initialStoreState);
    localStorage.setItem("keepSignedIn", false);
    history.push('/login');
  };

  const handleClickMusic = () => {
    setState(state => (state.navType = 'Music', { ...state }));
    handleCloseMenu();
    sessionStorage.setItem("sessionNavType", "Music");
    history.push('/dashboard');
  };
  const handleClickBlockchain = () => {
    setState(state => (state.navType = 'Blockchain', { ...state }));
    sessionStorage.setItem("sessionNavType", "Blockchain");
    handleCloseMenu();
    history.push('/dashboard');
  };

  const [openReportingPopper, setOpenReportingPopper] = useState(false);

  const ReportingPopper = (props) => { // TODO -> Rewrite for reusability
    return (
      <Popper
        open={openReportingPopper}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        className={styles.reportingPopper}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpenReportingPopper(false)}>
                <List>
                  <ListItem
                    button
                    component={NavLink}
                    to="/reporting-companies">
                    <div className={styles.popperImage}>
                      <BusinessIcon />
                    </div>
                    <ListItemText>Reporting Companies</ListItemText>
                  </ListItem>

                  <ListItem
                    button
                    component={NavLink}
                    to="/reporting">
                    <div className={styles.popperImage}>
                      <img src={StatementsSvg} alt="statements" />
                    </div>
                    <ListItemText>Statements</ListItemText>
                  </ListItem>
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  return (
    <div>
      <nav className={styles.navBar}>
        <div className={styles.navHeader}>
          <div className={styles.drawerToolbar}>
            <IconButton onClick={handleClickMenu} className={styles.selectedNavTypeIcon}><MenuIcon /></IconButton>
            <img
              src={recordLogo}
              alt=''
              className={styles.logo}
              onClick={() => {
                if (isAdmin) {
                  setState(state => (state.songsMap = {}, { ...state }));
                  setState(state => (state.initialLoad = false, { ...state }));
                  setState(state => (state.findSongsInDb = true, { ...state }));
                  history.push('/manage-users');
                } else {
                  history.push('/dashboard');
                }
              }}
            />
            <hr className={styles.vertical} />
            <div className={styles.selectedNavType}>{state.navType}</div>
            <Popover
              anchorEl={openDrawer}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(openDrawer)}
              onClose={handleCloseMenu}
            >
              <List>
                <ListItem
                  onClick={handleClickMusic}
                  button
                  key='music'
                  className={clsx(styles.drawerListItem, state.navType === 'Music' ? styles.selectedDrawerListItem : null)}
                >
                  <MusicNoteIcon className={styles.selectedNavTypeIcon} />
                  <div>Music</div>
                </ListItem>
                {process.env.REACT_APP_ENABLE_BLOCKCHAIN_SELECT_OPTION === 'true' &&
                  <ListItem
                    onClick={handleClickBlockchain}
                    button key='nft'
                    className={clsx(styles.drawerListItem, state.navType === 'Blockchain' ? styles.selectedDrawerListItem : null)}
                  >
                    <div className={styles.ethIconWidth}><EthIcon /></div>
                    <div>Blockchain</div>
                  </ListItem>}

              </List>
            </Popover>
          </div>
          <ul className={styles.navItems}>
            {isAdmin ?
              state.navType === 'Music' ?
                <>
                  <NavItem to="/portfolio"><FolderOutlinedIcon className={styles.navIcon} />Portfolio</NavItem>
                  <NavItem to="/manage-users"><PeopleAltOutlinedIcon className={styles.navIcon} />Users</NavItem>
                  <NavItem to="/manage-account-holders"><PermIdentityIcon className={styles.navIcon} />Account Holders</NavItem>
                  <NavItem to="/manage-artists"><AlbumIcon className={styles.navIcon} />Artists</NavItem>
                  <NavItem to="/songs"><LibraryMusicIcon className={styles.navIcon} />Songs</NavItem>
                  {process.env.REACT_APP_ENABLE_REVIEW_PAGE_TAB === 'true' ? <NavItem to="/review-page"><StorageIcon className={styles.navIcon} />Review Page</NavItem> : ""}
                  <NavItem to="/merge-history"><MergeTypeIcon className={styles.navIcon} />Merge History</NavItem>
                  <div className={styles.navItemWithOptions} onClick={() => setOpenReportingPopper(!openReportingPopper)} ref={anchorRef}>
                    <BusinessIcon className={styles.navIcon} />
                    Reporting
                    <ArrowDropDownIcon className={styles.moreOptionsIcon} />
                    <ReportingPopper />
                  </div>
                  <NavItem to="/settings"><SettingsIcon className={styles.navIcon} />Settings</NavItem>
                </>
                :
                state.navType === 'Blockchain' ?
                  <>
                    <NavItem to="/portfolio" className={styles.navItemSmall} ><FolderOutlinedIcon className={styles.navIcon} />Portfolio</NavItem>
                    <NavItem to="/manage-users" className={styles.navItemSmall} ><PeopleAltOutlinedIcon className={styles.navIcon} />Users</NavItem>
                    <NavItem to="/manage-account-holders" className={styles.navMedium} ><PermIdentityIcon className={styles.navIcon} />Account Holders</NavItem>
                    <NavItem to="/nft-reports" className={styles.navItemSmall} ><DescriptionIcon className={styles.navIcon} />Reports</NavItem>
                    <NavItem to="/nft-catalog" className={styles.navItemBig}><StyleIcon className={styles.navIcon} />Blockchain Catalog</NavItem>
                    {process.env.REACT_APP_ENABLE_BLOCKCHAIN_ANALYZE_PAGE ?
                      <NavItem className={clsx(styles.navItemBig, styles.analyze)} to="/nft-analyze"><p className='graphIcon'><TimelineIcon className={styles.navIcon} /></p>Blockchain Analyze</NavItem> : ""}
                    <NavItem to="/agent-statistics" className={styles.navItemBig}><SettingsIcon className={styles.navIcon} />Blockchain Agent</NavItem>
                  </>
                  :
                  null
              :
              state.navType === 'Music' ?
                <>
                  <NavItem to="/dashboard" className={styles.navItemDefault}><DashboardOutlinedIcon className={styles.navIcon} />Dashboard</NavItem>
                  <NavItem to="/portfolio" className={styles.navItemDefault}><FolderOutlinedIcon className={styles.navIcon} />Portfolio</NavItem>
                  <NavItem to="/reporting" className={styles.navItemDefault}><BusinessIcon className={styles.navIcon} />Reporting</NavItem>
                  {process.env.REACT_APP_ENABLE_EARNINGS_PAGE === 'true' ? <NavItem to='/earnings' className={styles.navItemDefault}>
                    <BarChartOutlined className={styles.navIcon} />
                    Earnings
                  </NavItem> : ""}
                  {process.env.REACT_APP_ENABLE_REVIEW_PAGE_TAB === 'true' ? <NavItem to="/review-page" className={styles.navItemDefault}> <StorageIcon className={styles.navIcon} />Review Page</NavItem> : ""}
                </>
                :
                state.navType === 'Blockchain' ?
                  <>
                    <NavItem to="/dashboard" className={styles.navItemDefault}><DashboardOutlinedIcon className={styles.navIcon} />Dashboard</NavItem>
                    <NavItem to="/portfolio" className={styles.navItemDefault}><FolderOutlinedIcon className={styles.navIcon} />Portfolio</NavItem>
                  </>
                  :
                  null
            }
          </ul>
          <div className={styles.userInfo}>
            <div className={styles.avatar}>
              <Button onClick={handleClick} component="span" disableTouchRipple classes={{ label: styles.customLabelForButton }}>
                <Avatar className={styles.colorDefault}>{state.user.firstName.charAt(0) + state.user.lastName.charAt(0)}</Avatar>
                <ArrowDropDown style={{ color: '#FFFFFF' }} />
              </Button>
              <Popover
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuList>
                  <MenuItem>
                    <Link
                      component={NavLink}
                      exact={true}
                      to="/user-profile"
                    >
                      My user
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link onClick={handleLogout}>Logout</Link>
                  </MenuItem>
                </MenuList>
              </Popover>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default function PageLayout({ children, ...props }) {
  const styles = useStyles();
  return (
    <div className={styles.pageContainer} id="MainNav">
      {props.disableNavigation ? null :
        <Navigation />
      }
      <div className={clsx(styles.pageContainerContent, props.disableRootPadding ? null : styles.rootPadding)}>
        {children}
      </div>
    </div>
  );
}
