
export function capitalizeFirstLetter(str){
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export function shortenLabel(label, maxLength){
    if (label.length > maxLength) {
        return label.slice(0, maxLength) + '...';
    }
    return label;
};

export function removeBraces(str) {
    if (!str) return;
    str = str.trim();
    if (str.startsWith('{') && str.endsWith('}')) {
        return str.slice(1, -1).trim();
    }
    return str;
}