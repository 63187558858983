import { Box, CircularProgress, Typography, makeStyles } from "@material-ui/core";
import { Fragment, useEffect, useRef, useState } from "react";
import { BrandSearchInput } from "../CoreComponents/BrandInput";
import MergedSongsTree from "./MergedSongsTree";
import useAbortableFetch from "../../hooks/useAbortableFetch";
import { useDebounce } from "../../hooks/useDebounce";
import { useInfiniteScrolling } from "../../hooks/useInfiniteScrolling";
import UnmergeSongModal from "./UnmergeSongModal";
import MoveSongModal from "./MoveSongModal";
import { BrandTooltip } from "../CoreComponents/BrandTooltip";
import { ExactWordTooltipMessage } from "../../constants/messageConstants";
import { BrandCheckbox } from "../CoreComponents/BrandCheckbox";

const useStyles = makeStyles((theme) => ({
    pageMainHeader: {
        font: 'normal normal bold 25px Roboto'
    },
    searchFieldWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: '20px',
        marginBottom: '25px'
    },
    searchField: {
        width: theme.spacing(55.625)
    },
    mergedSongsTreeWrapper: {
        marginLeft: '-40px', 
        height: '75vh', 
        overflow: 'auto',
        scrollBehavior: 'smooth', 
        paddingRight: '15px',

        '&::-webkit-scrollbar': {
            height: '12px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.lightGray,
            borderRadius: '2px',
            border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.primary.darkGray,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.primary.black,
            borderRadius: '2px',
            border: `1px solid ${theme.palette.primary.lightGrayBorder}`,
        },
    },
    errorMessage: {
        color: theme.palette.primary.alert,
        textAlign: 'center',
        marginTop: '10px'
    },
    filtersWrapper: {
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    irrelevantResultsWrapper: {},
    greenText: { color: theme.palette.primary.main },
    whiteText: { color: theme.palette.text.primary }
}));

function buildMergedTree(pairs, inputPair) {
    const song = { 
        ...inputPair,
        lastModifiedDate: inputPair.lastModifiedDate.split('T')[0]
    };

    const parentInfo = pairs.find((pair) => pair.id === inputPair.mergedIntoSongId);

    if (parentInfo) {
        song.parentId = parentInfo.id;
        song.parentTitle = parentInfo.displayName;
        song.parentArtist = parentInfo.artist;
    }

    const mergedSongs = pairs
        .filter((pair) => pair.mergedIntoSongId === inputPair.id)
        .map((pair) => buildMergedTree(pairs, pair));

    if (mergedSongs.length > 0) {
        song.mergedSongs = mergedSongs;
    }

    return song;
}

const MergeHistory = () => {
    const LIMIT = 10;
    const DEBOUNCE_TIMEOUT = 500;
    const styles = useStyles();

    const [songsData, setSongsData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [expandedItems, setExpandedItems] = useState([]);
    const [selectedSong, setSelectedSong] = useState(null);

    const [showUnmergeModal, setShowUnmergeModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [hideIrrelevantSearchResults, setHideIrrelevantSearchResults] = useState(true);
    const [isLoadingNewItems, setIsLoadingNewItems] = useState(false);

    const offsetRef = useRef(0);
    const elementRef = useRef(null);

    const searchQuery = useDebounce(searchInput, DEBOUNCE_TIMEOUT);
    const abortableFetch = useAbortableFetch();

    const [setIsFetching, setIsDisabled] = useInfiniteScrolling(elementRef, async () => await fetchMergedSongsData());

    //Remove outer scrollbar
    useEffect(() => {
        const rootElement = document.getElementById('MainNav');
        if (rootElement) {
            rootElement.style.scrollbarWidth = 'none'; // Firefox
            rootElement.style['-ms-overflow-style'] = 'none'; // Edge
        }
        return () => {
            if (rootElement) {
                rootElement.style.scrollbarWidth = '';
                rootElement.style['-ms-overflow-style'] = '';
            }
        };
    }, []);

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const fetchMergedSongsData = async () => {
        try {
            setIsLoadingNewItems(true);
            const { songs } = await abortableFetch('GET', '/api/merge-history', {
                query: { searchQuery, offset: offsetRef.current, limit: LIMIT }
            });

            await delay(200);

            if (songs.length === 0 && offsetRef.current === 0) {
                setShowErrorMessage(true);
                return;
            }

            if (songs.length === 0) {
                setIsDisabled(true);
                return;
            }


            const songsTree = songs
                .filter((pair) => !songs.some((p) => p.id === pair.mergedIntoSongId))
                .map((pair) => buildMergedTree(songs, pair));

                setSongsData((prev) => {
                    const merged = [...prev, ...songsTree];
                    return merged.filter((song, index, self) =>
                        self.findIndex((s) => s.id === song.id) === index
                    );
                });
                
            offsetRef.current += LIMIT;
            setIsFetching(false);
            setIsDisabled(false);
            setShowErrorMessage(false);
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoadingNewItems(false);
        }
    }

    const refreshSongsData = async () => {
        offsetRef.current = 0;
        setSongsData([]);
        setIsDisabled(false);
        await fetchMergedSongsData();
    }

    useEffect(() => {
        offsetRef.current = 0;
        setSongsData([]);
        setIsDisabled(false);
        fetchMergedSongsData();
        setExpandedItems([]);
    }, [searchQuery]);

    const irrelevantSongsTooltipTitle =
        <Box>
            <Typography className={styles.greenText}>Merged songs that don't match the provided search items are hidden by default.</Typography>
            <Typography>&nbsp;</Typography> {/* Empty line */}
            <Typography className={styles.whiteText}>Uncheck the box if you want to see the whole list.</Typography>
        </Box>;

    return (
        <Box component='div'>
            { showUnmergeModal && (
                <UnmergeSongModal
                    song={selectedSong}
                    open={showUnmergeModal}
                    onClose={() => setShowUnmergeModal(false)}
                    refreshData={refreshSongsData}
                />
            ) }
            { showMoveModal && (
                <MoveSongModal 
                    song={selectedSong} 
                    open={showMoveModal} 
                    onClose={() => setShowMoveModal(false)}
                    refreshData={refreshSongsData} 
                />
            ) }  
            <Typography className={styles.pageMainHeader}>
                Merge History
            </Typography>
            <Box className={styles.filtersWrapper}>
                <Box className={styles.searchFieldWrapper}>
                    <BrandSearchInput
                        placeholder='Search for Song'
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        classes={{ input: styles.searchField }}
                    />
                    <BrandTooltip title={ExactWordTooltipMessage} />
                </Box>
                <Box className={styles.irrelevantResultsWrapper}>
                    <BrandCheckbox
                        checked={hideIrrelevantSearchResults} 
                        onChange={() => setHideIrrelevantSearchResults(!hideIrrelevantSearchResults)} 
                        label='Hide irrelevant results' 
                    />
                    <BrandTooltip title={irrelevantSongsTooltipTitle} />
                </Box>
            </Box>
            <Box ref={elementRef} className={styles.mergedSongsTreeWrapper}>
                { !showErrorMessage ? (
                    <>
                        <MergedSongsTree
                            songsData={songsData}
                            expandedItems={expandedItems}
                            setSelectedSong={setSelectedSong}
                            setExpandedItems={setExpandedItems}
                            setShowUnmergeModal={setShowUnmergeModal}
                            setShowMoveModal={setShowMoveModal}
                            searchQuery={searchQuery}
                            hideIrrelevantSearchResults={hideIrrelevantSearchResults}
                        />
                        {isLoadingNewItems &&
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
                                <CircularProgress />
                            </Box>}
                    </>
                ) : (
                    <Typography className={styles.errorMessage}>
                        No results found.
                    </Typography>
                ) }
            </Box>
        </Box>
    );
}

export default MergeHistory;