import { Box, IconButton, TablePagination, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import { Fragment, useState } from "react";
import { BrandButton, SecondaryBrandButton, TempBrandButton, TempBrandDeleteButton } from "../CoreComponents/BrandButton";
import { ButtonSize } from "../../constants/buttonConstants";
import AdditionalAccountHolders from "../Songs/AdditionalAccountHolders";
import { removeBraces, shortenLabel } from "../utils/StringUtils";
import { MERGE_HISTORY_ROWS_PER_PAGE } from "../utils/PaginationUtils";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        background: theme.palette.background.darkNuanceVersion4,
        display: 'flex',
        flexDirection: 'row',
        padding: '17.5px 0px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '6px',
        marginBottom: theme.spacing(2),
        '&:hover': {
            transform: 'scale(1.006)',
            zIndex: 15,
            boxShadow: `0 8px 20px ${theme.palette.primary.boxShadow}`,
        },
    },
    pointerCursor: {
        cursor: 'pointer',
    },
    slideInAnimation: {
        transform: 'translateX(10%)',
        animation: '$slideIn 0.3s ease-out forwards',
    },
    '@keyframes slideIn': {
        to: {
            transform: 'translateX(0)',
        },
    },
    infoContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '2px',
    },
    songTitleItem: {
        width: '15vw',
        wordWrap: 'break-word',
    },
    artistNameItem: {
        width: '8vw',
    },
    accountHolderItem: {
        width: '12vw',
    },
    mainContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '25px',
    },
    itemWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '40px',
        borderRadius: '6px',
        marginBottom: theme.spacing(1),
        '& .MuiIconButton-root:disabled': {
            color: theme.palette.primary.darkGray,
        },
    },
    emptySpace: {
        height: '56px',
        width: '56px'
    },
    icon: {
        color: 'white',
        fontSize: '32px'
    },
    mergedSongsCount: {
        color: theme.palette.text.greenNuanceVersion2
    },
    actionsWrapper: {
        marginRight: '25px',
        display: 'flex',
        flexDirection: 'row',
        columnGap: '50px',
        alignItems: 'center'
    },
    leftSideContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    buttonsWrapper: {
        width: '250px',
        display: 'flex',
        justifyContent: 'flex-end',
        columnGap: '25px',
        '& button': {
            width: '123px',
        }
    },
    displayName: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '22px',
        fontFamily: 'Roboto, sans-serif',
    },
    fontSizeA: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        fontFamily: 'Roboto, sans-serif',
        '& .MuiTypography-root': {
            font: 'normal normal medium 16px/19px Roboto',
        },
    },
    fontSizeB: {
        font: 'normal normal normal 14px/21px Roboto',
    },
    defaultBorder: {
        border: `1px solid ${theme.palette.primary.lightYellow}`,

    },
    greenBorder: {
        border: `1px solid ${theme.palette.primary.main}`,
    },
    filterResultsText: {
        color: theme.palette.primary.attention,
    },
    noResultInMergedSongsMessage: {
        marginBottom: theme.spacing(5),
        marginLeft: theme.spacing(7.5),
        color: theme.palette.primary.attention,
    }

}));

const RecursiveSongView = ({
    object,
    isOrphanNode = true,
    toggleExpand,
    expandedItems,
    setSelectedSong,
    setShowUnmergeModal,
    setShowMoveModal,
    pageSize = null,
    page = null,
    handleChangePagePassed = null,
    searchQuery,
    hideIrrelevantSearchResults,
}) => {
    const SONG_NAME_MAX_LENGTH = 26;
    const ARTIST_NAME_MAX_LENGTH = 12;
    
    const styles = useStyles();
    const [pages, setPages] = useState(new Map());

    const paginatedSongs = (pageSize !== null) && (page !== null) ? object.slice(page * pageSize, page * pageSize + pageSize) : object;
    const searchQueryWithoutBraces = removeBraces(searchQuery);
    
    const handleChangePage = (childId, event, newPage) => {
        setPages((prevPages) => new Map(prevPages).set(childId, newPage));
    };

    const handleUnmergeButtonClick = (song) => {
        setSelectedSong(song);
        setShowUnmergeModal(true);
    }

    const handleMoveButtonClick = (song) => {
        setSelectedSong(song);
        setShowMoveModal(true);
    }

    const handleExpand = (id) => {
        toggleExpand(id);
        setPages((prevPages) => new Map(prevPages).set(id, 0));
    }

    const getBorderStyle = (isOrphan, id) => {
        if (!isOrphan) {
            if (searchQueryWithoutBraces) {
                return styles.greenBorder;
            }
        }
        else {
            if (expandedItems.includes(id) || searchQueryWithoutBraces) {
                return styles.greenBorder;
            }
        }
        return styles.defaultBorder;
    }

    return (
        <Fragment>
            {paginatedSongs.map(({
                id,
                displayName,
                artist,
                mergedSongs,
                lastModifiedDate,
                parentId,
                parentTitle,
                parentArtist,
                accountHolders
            }, index) => {
                
                let queryMatchingSongs = 0;
                const totalSongs = mergedSongs ? mergedSongs.length + 1 : 0;
                let filteredMergedSongs = mergedSongs;
                let mergedSongsContainDetailsAboutSearchQuery = false;
                const currentPage = pages.get(id) || 0;

                if (searchQueryWithoutBraces) {
                    const query = searchQueryWithoutBraces.toLowerCase();
                    const isMatch = (text) => text.toLowerCase().includes(query);
                    if (isMatch(displayName) || isMatch(artist)) queryMatchingSongs++;

                    const filtered = mergedSongs?.filter(song => {
                        const match = isMatch(song.displayName) || isMatch(song.artist);
                        if (match) {
                            queryMatchingSongs++;
                            mergedSongsContainDetailsAboutSearchQuery = true;
                        }
                        return match;
                    });
                    if (hideIrrelevantSearchResults) {
                        filteredMergedSongs = filtered;
                    }
                }
                
                return (
                    <Box className={`${styles.itemWrapper} ${isOrphanNode && styles.slideInAnimation}`} key={id}>
                        <Box className={`${styles.mainContainer} ${getBorderStyle(isOrphanNode, id)} ${isOrphanNode && styles.pointerCursor}`}
                            onClick={() => handleExpand(id)}>
                            <Box className={styles.leftSideContentWrapper}> 
                                {filteredMergedSongs ? (
                                    <IconButton>
                                        {expandedItems.includes(id)
                                            ? <ExpandMore className={styles.icon} />
                                            : <ChevronRight className={styles.icon} />
                                        }
                                    </IconButton>
                                ) : (
                                    <Box className={styles.emptySpace} />
                                )}
                                <Box className={styles.mainContentWrapper}>
                                    <Box className={`${styles.infoContentWrapper} ${styles.songTitleItem}`}>
                                        <Typography className={styles.fontSizeB}>Song Title</Typography>
                                        <Tooltip title={displayName}>
                                            <Typography className={styles.displayName}>{shortenLabel(displayName, SONG_NAME_MAX_LENGTH)}</Typography>
                                        </Tooltip>
                                    </Box>
                                    <Box className={`${styles.infoContentWrapper} ${styles.artistNameItem}`}>
                                        <Typography className={styles.fontSizeB}>Artist</Typography>
                                        <Tooltip title={artist}>
                                            <Typography className={styles.displayName}>{shortenLabel(artist, ARTIST_NAME_MAX_LENGTH)}</Typography>
                                        </Tooltip>
                                    </Box>
                                    <Box className={`${styles.infoContentWrapper} ${styles.accountHolderItem}`}>
                                        <Typography className={styles.fontSizeB}>Account Holder</Typography>
                                        <AdditionalAccountHolders
                                            accountHolders={JSON.parse(accountHolders)}
                                            showAccountHoldersText={false}
                                            textStyle={styles.fontSizeA}
                                        />
                                    </Box>
                                    <Box className={styles.infoContentWrapper}>
                                        <Typography className={styles.fontSizeB}>Last Modified Date</Typography>
                                        <Typography className={styles.fontSizeA}>{lastModifiedDate}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={styles.actionsWrapper}>
                                {filteredMergedSongs &&
                                    <Box>
                                        <Typography className={styles.mergedSongsCount}>
                                            {mergedSongs.length === 1
                                                ? `${mergedSongs.length} merged song`
                                                : `${mergedSongs.length} merged songs`
                                            }
                                        </Typography>
                                        {
                                            isOrphanNode && searchQueryWithoutBraces &&
                                            <Typography className={styles.filterResultsText}>
                                                {queryMatchingSongs === 1
                                                ? `${queryMatchingSongs} result found from ${totalSongs} total entries.`
                                                : `${queryMatchingSongs} results found from ${totalSongs} total entries.`}
                                            </Typography>
                                        }
                                    </Box>
                                }
                                <Box className={styles.buttonsWrapper}>
                                    <TempBrandButton
                                        size={ButtonSize.SMALL}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleMoveButtonClick({
                                                id,
                                                displayName,
                                                artist,
                                                parentId,
                                                parentTitle,
                                                parentArtist
                                            });
                                        }}
                                    >
                                        Move
                                    </TempBrandButton>
                                    {!isOrphanNode && (
                                        <TempBrandDeleteButton
                                            size={ButtonSize.SMALL}
                                            onClick={() => handleUnmergeButtonClick({
                                                id,
                                                displayName,
                                                artist,
                                                parentId,
                                                parentTitle,
                                                parentArtist
                                            })}
                                        >
                                            Unmerge
                                        </TempBrandDeleteButton>
                                    )}
                                </Box>
                            </Box>
                        </Box>

                        {expandedItems.includes(id) && filteredMergedSongs &&
                            <RecursiveSongView
                                object={filteredMergedSongs}
                                isOrphanNode={false}
                                toggleExpand={toggleExpand}
                                expandedItems={expandedItems}
                                setSelectedSong={setSelectedSong}
                                setShowUnmergeModal={setShowUnmergeModal}
                                handleChangePagePassed={(event, newPage) => handleChangePage(id, event, newPage)}
                                setShowMoveModal={setShowMoveModal}
                                searchQuery={searchQueryWithoutBraces}
                                pageSize={MERGE_HISTORY_ROWS_PER_PAGE}
                                page={currentPage}
                            />
                        }
                        {
                            // Render warning message if the only search result is the parent song.
                            expandedItems.includes(id) && !mergedSongsContainDetailsAboutSearchQuery && searchQueryWithoutBraces && isOrphanNode && hideIrrelevantSearchResults &&
                            <Typography className={styles.noResultInMergedSongsMessage}>
                                None of the merged song details matches the provided search item. Only the top level song contains the searched item.
                            </Typography>
                        }
                        {
                            // Render pagination after the last non-orphan node.
                            index === paginatedSongs.length - 1 && !isOrphanNode &&
                            <TablePagination
                                component="div"
                                count={object.length}
                                page={page}
                                rowsPerPage={pageSize}
                                onPageChange={handleChangePagePassed}
                                labelRowsPerPage=""
                                rowsPerPageOptions={[]}
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                            />
                        }
                    </Box>
                )
            })}
        </Fragment>
    )
}

export default RecursiveSongView;