import './App.css';
import React, { useState, useEffect } from 'react';
import { StoreContext, initialStoreState } from './store/Store.js';
import { Route, Redirect } from 'react-router';
import { HashRouter as Router, Switch, BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Upload from './components/upload/Upload';
import SplashPage from './components/SplashPage/SplashPage';
import LoginPage from './components/LoginPage/LoginPage';
import AcceptInvite from './components/AcceptInvite/AcceptInvite'
import Dashboard from './components/Dashboard/Dashboard'
import ManageUsers from './components/ManageUsers/ManageUsers'
import ManageArtists from './components/ManageArtists/ManageArtists'
import ChangePassword from './components/ChangePassword/ChangePassword'
import ForgotennPassword from './components/ForgottenPassword/ForgottenPassword'
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import PageLayout from './components/Navigation/Navigation';
import DataMapping from './components/DataMapping/DataMapping';
import AuthPage from './components/CoreComponents/AuthPage';
import Songs from './components/Songs/Songs';
import MissingData from './components/MissingData/MissingData';
import { EditAsNormalUser } from './components/ManageUsers/EditUser';
import ManageAccountHolders from './components/ManageAccountHolders/ManageAccountHolders';
import { abortRequests, getData, recreateAbortController } from './components/utils/FetchUtils';
import { useStoreContext } from './store/Store'
import ShareDiligence from './components/ShareDiligence.js/ShareDiligence';
import { UploadNft } from './components/Nft/Nft';
import PortfolioPage from './components/Portfolio/PortfolioPage';
import { NftAgentStatistics } from './components/Nft/NftAgentStatistics';
import NftReports from './components/NftReports/NftReports';
import NftReport from './components/NftReports/NftReport/NftReport';
import NftCatalog from './components/NftCatalog/NftCatalog';
import NftAnalyze from './components/NftAnalyze/NftAnalyze';
import CollectionStats from './components/NftAnalyze/CollectionStats/CollectionStats';
import ReviewPage from './components/ReviewPage/ReviewPage';
import LearnMore from './components/HomePage/LearnMore';
import ThankYouMessage from './components/HomePage/ThankYouMessage';
import Cookies from './components/HomePage/Cookies';
import ThermsOfUse from './components/HomePage/ThermsOfUse';
import PrivacyPolicy from './components/HomePage/PrivacyPolicy';
import Accessibility from './components/HomePage/Accessibility';
import Contact from './components/HomePage/Contact';
import HomePage from './components/HomePage/HomePage';
import './fonts/ITCAvantGardeGothicStd-Normal.otf'

import Earnings from './components/Earnings/Earnings';
import { useIsFirstRender } from './hooks/useIsFirstRender';
import PageNotFound from './components/PageNotFound/PageNotFound';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import MergeHistory from './components/MergeHistory/MergeHistory.js';
import RevenueSplits from './components/RevenueSplits/RevenueSplits.js';
import ReportingCompanies from './components/ReportingCompanies/ReportingCompanies.js';
import ManageShares from './components/ManageShares/ManagesShares.js';
import ErrorBoundaryRoute from './components/Router/ErrorBoundaryRoute.js';
import ReportingPage from './components/Reporting/ReportingPage.js';
import GenerateRoyaltyStatement from './components/GenerateRoyaltyStatement/GenerateRoyaltyStatement.js';
require("dotenv").config();

const PRIMARY_MAIN_COLOR = '#68DC6C';
const PRIMARY_HOVER_COLOR = '#4CAF50';

const AppTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN_COLOR,
      hover: PRIMARY_HOVER_COLOR,
      light: '#80e27e',
      dark: '#087f23',
      contrastText: '#FFFFFF',
      alert: '#F42A82',
      alertHover: '#C51162',
      lightGray: '#A5A5A5',
      border: '#3C3C3C',
      warning: '#F96C00',
      attention: '#EDE107',
      success: '#00c851',
      disabled: '#9B104A',
      lightYellow: '#FFFFFF61',
      lightGrayBorder: '#707070',
      darkGray: '#535353',
      darkGrayNuance: '#393939',
      black: '#111111',
      darkGreen: '#274028',
      blue: '#56c9fc',
      delete: 'var(--red-alert)',
      deleteHover: '#F42A82',
      boxShadow: '#0000004D',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
      hint: '#FFFFFF99',
      dark: '#222222',
      lightGray: '#b8b8b8',
      lightYellow: '#FFFFFFDE',
      darkNuance: '#FFFFF99',
      greenNuance: '#77FF76',
      greenNuanceVersion2: '#1dbc01',
      greenNuanceVersion3: '#03B56D',
      black: '#0C0C0C',
    },
    background: {
      default: '#1B1B1B',
      dark: '#161616',
      darkNuance: '#171717',
      darkNuanceVersion3: '#1A1A1A',
      darkNuanceVersion4: '#151515',
      lime: '#62e162',
      grayNuance: '#343434',
      darkGreen: '#39ac55',
      partialMatch: 'var(--pink-peach)',
      suggestedSongsMatch: 'var(--yellow)',
      suggestedSongsDropdown: 'var(--dark-gray)',
      manualMatch: 'var(--blue)'
    }
  },
  typography: {
    allVariants: {
      font: 'normal normal normal 14px/18px Segoe UI',
      letterSpacing: '1.26px',
    }
  },
  shape: {
    borderRadius: '8px',
  },
  overrides: {
    MuiPaper: {
      root: {
        '& .MuiAutocomplete-groupLabel': {
          backgroundColor: '#1B1B1B',
          color: 'white',
        },
        backgroundColor: '#1B1B1B',
        borderRadius: '16px',
        '& .MuiPickersCalendarHeader-switchHeader': {
          '& .MuiButtonBase-root': {
            backgroundColor: 'transparent',
            color: 'white',
          },
          '& .MuiPickersCalendarHeader-iconButton': {
            backgroundColor: 'transparent !important',
            color: 'white',
          }
        },
        '& .MuiPickersCalendar-week': {
          '& .MuiPickersDay-daySelected': {
            backgroundColor: PRIMARY_MAIN_COLOR,
            color: 'white',
            '&:hover': {
              backgroundColor: PRIMARY_HOVER_COLOR,
              color: 'white'
            },
            '&:focus': {
              background: PRIMARY_HOVER_COLOR,
              boxShadow: 'none'
            },
          }
        }
      },
    },
    MuiIconButton: {
      root: {
        color: PRIMARY_MAIN_COLOR,
      }
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: '#323232'
        },
        '&:hover': {
          backgroundColor: '#252525 !important',
        },
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '16px'
      }
    },
    MuiTable: {
      tableRow: {
        background: '#252525'
      },
      tableRowCell: {
        background: '#1B1B1B'
      },
      tableHead: {
        background: '#323232'
      },
      activeHeadCell: {
        color: PRIMARY_MAIN_COLOR,
      }
    },
    MuiAlert: {
      filledError: {
        color: '#000',
        backgroundColor: 'var(--red-alert)'
      },
      filledSuccess: {
        color: '#000',
        backgroundColor: PRIMARY_MAIN_COLOR
      }
    }
  }
});

// const InterceptorComponent = ({ children }) => {
//   const location = useLocation();
//   const firstRender = useIsFirstRender();

//   useEffect(() => {
//     if (firstRender) return;

//     console.log('changed');

//     abortRequests();
//     recreateAbortController();
//   }, [location.pathname]);

//   return (
//     children
//   );
// };


const AutoLoginComponent = ({setStoreState, children }) => {
  const [state, setState] = useStoreContext();
  const location = useLocation();
  const history = useHistory();
  const pagesFromWhichAutologinShouldntApply = [
    "/accept-invite",
  ];
  
  useState(() => {
    const autoLogin = localStorage.getItem("keepSignedIn");
    
    if (JSON.parse(autoLogin) && !pagesFromWhichAutologinShouldntApply.includes(location.pathname)) {
    (async () => {
      setStoreState(state => (state.autoLoginLoading = true, { ...state }));
      const data = await getData(process.env.REACT_APP_SERVER_HOST + '/api/user/me');
      if (data) {
        setStoreState(state => (state.user = data, state.autoLoginLoading = false, { ...state }));
      } else {
        setStoreState(state => (state.autoLoginLoading = false, { ...state }));
      }
    })();
  }
})
  
  useEffect(() => {
    if (state.autoLoginLoading && state.returnUrl) {
      history.push(state.returnUrl);
      setState(state => (state.returnUrl = undefined, { ...state }))
    }
  }, [state.autoLoginLoading])


  return state.autoLoginLoading ?
    <div style={{ background: '#1B1B1B' }}></div>
    :
    children
}

function App() {
  const [isFetchAttached, setIsFetchAttached] = useState(false);
  const [storeState, setStoreState] = useState(initialStoreState);

  function handleCloseMessage() {
    setStoreState(state => (state.alertSettings.open = false, { ...state }));
  };


  useEffect(() => {
    const originalFetch = window.fetch;
    window.originalFetch = originalFetch;
    window.fetch = function (input, init) {
      // console.log('fetch', input);
      return originalFetch(input, init)
        .then(async resp => {
          if (!resp.ok) {
            const text = await resp.text();

            let parsedError = '';
            try {
              parsedError = JSON.parse(text).msg;
            } catch {
              parsedError = text;
            }

            switch (resp.status) {
              case 401:
                setStoreState(state => (state.user = null, { ...state }));
                // $snackbarMessage.set(parsedError);
                return;
              case 413:
                throw new Error('File size too large.')
              default:
                break;
            }

            throw new Error(parsedError);
          }

          // parses JSON response into native JavaScript objects
          return resp.json()
            .catch(err => console.error('response parse error', input, 'error', err));
        },
          err => {
            // if fetch throws an error
            //   $snackbarMessage.set(err.message);
            console.log('fetch error', err);
          });
    }

    setStoreState(state => (state.returnUrl = window.location.href.replace(window.location.origin, ''), { ...state }))

    setIsFetchAttached(true);
    return () => {
      window.fetch = originalFetch;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={AppTheme}>
      {isFetchAttached ?
        <StoreContext.Provider value={[storeState, setStoreState]}>
          {process.env.REACT_APP_SPLASH_PAGE === 'splashpage' ?
            <Router>
              <ErrorBoundary>
                <Switch>
                  <Route exact path="/signup" component={SplashPage} />
                  <Redirect exact path="/" to="/signup" />
                </Switch>
              </ErrorBoundary>
            </Router>
            :
            <BrowserRouter>
              {/* <InterceptorComponent> */}
                <AutoLoginComponent setStoreState={setStoreState}>

                  {!storeState.user ?
                    <AuthPage hidePageProps={storeState.hidePageProps}>
                      <Switch>
                        <ErrorBoundaryRoute exact path="/home-page" component={HomePage} />
                        <ErrorBoundaryRoute exact path="/accept-invite" component={AcceptInvite} />
                        <ErrorBoundaryRoute exact path="/learn-more" component={LearnMore} />
                        <ErrorBoundaryRoute exact path="/thank-you-message" component={ThankYouMessage} />
                        <ErrorBoundaryRoute exact path="/cookies" component={Cookies} />
                        <ErrorBoundaryRoute exact path="/therms-of-use" component={ThermsOfUse} />
                        <ErrorBoundaryRoute exact path="/privacy-policy" component={PrivacyPolicy} />
                        <ErrorBoundaryRoute exact path="/accessibility" component={Accessibility} />
                        <ErrorBoundaryRoute exact path="/contact" component={Contact} />
                        <ErrorBoundaryRoute exact path="/change-password" component={ChangePassword} />
                        <ErrorBoundaryRoute exact path="/login" component={LoginPage} />
                        <ErrorBoundaryRoute exact path="/forgotten-password" component={ForgotennPassword} />
                        <ErrorBoundaryRoute exact path="/nft-report" component={NftReport} />
                        <ErrorBoundaryRoute exact path="/share-diligence" component={ShareDiligence} />
                        <Route exact path="/error-page" component={PageNotFound} />
                        <Redirect from="*" to="/home-page" />
                      </Switch>
                    </AuthPage>
                    :
                    <PageLayout disableNavigation={storeState.hideNavigation} disableRootPadding={storeState.disableRootPadding}>

                      {storeState.user.userType === 1 ?
                        storeState.navType === 'Music' ?
                          <Switch>
                            <ErrorBoundaryRoute exact path="/portfolio" component={PortfolioPage} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/manage-users" component={ManageUsers} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/manage-account-holders" component={ManageAccountHolders} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/manage-artists" component={ManageArtists} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/songs" component={Songs} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/settings" component={DataMapping} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/upload" component={Upload} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/user-profile" component={EditAsNormalUser} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/share-diligence" component={ShareDiligence} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/nft-report" component={NftReport} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/review-page" component={ReviewPage} checkUser={true} />
                            <Route exact path="/error-page" component={PageNotFound} />
                          <ErrorBoundaryRoute exact path="/merge-history" component={MergeHistory} checkUser={true} />
                            { process.env.REACT_APP_SHOW_REVENUE_SPLIT === 'true' ?
                            <ErrorBoundaryRoute exact path="/revenue-split" component={RevenueSplits} checkUser={true} />
                              :
                              null
                            }
                            <ErrorBoundaryRoute exact path="/reporting-companies" component={ReportingCompanies} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/reporting" component={ReportingPage} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/manage-shares" component={ManageShares} checkUser={true} />
                            <ErrorBoundaryRoute exact path="/generate-royalty-statement" component={GenerateRoyaltyStatement} checkUser={true} />
                            <Redirect from="*" to="/manage-users" />
                          </Switch>

                          :
                          storeState.navType === 'Blockchain' ?
                            <Switch>
                              <ErrorBoundaryRoute exact path="/portfolio" component={PortfolioPage} checkUser={true} />
                              <ErrorBoundaryRoute exact path="/manage-users" component={ManageUsers} checkUser={true} />
                              <ErrorBoundaryRoute exact path="/manage-account-holders" component={ManageAccountHolders} checkUser={true} />
                              <ErrorBoundaryRoute exact path="/nft-reports" component={NftReports} checkUser={true} />
                              <ErrorBoundaryRoute exact path="/agent-statistics" component={NftAgentStatistics} checkUser={true} />
                              <ErrorBoundaryRoute exact path="/upload-nft" component={UploadNft} checkUser={true} />
                              <ErrorBoundaryRoute exact path="/user-profile" component={EditAsNormalUser} checkUser={true} />
                              <ErrorBoundaryRoute exact path="/nft-report" component={NftReport} checkUser={true} />
                              <ErrorBoundaryRoute exact path="/nft-catalog" component={NftCatalog} checkUser={true} />
                              <ErrorBoundaryRoute exact path="/nft-analyze" component={NftAnalyze} checkUser={true} />
                              <ErrorBoundaryRoute exact path="/nft-analyze/:collectionName" component={CollectionStats} checkUser={true} />
                              <Route exact path="/error-page" component={PageNotFound} />
                              <Redirect from="*" to="/manage-users" />
                            </Switch>
                            :
                            null
                        :
                        <Switch>
                          <ErrorBoundaryRoute exact path='/earnings' component={Earnings} checkUser={true} />
                          <ErrorBoundaryRoute exact path="/user-profile" component={EditAsNormalUser} checkUser={true} />
                          <ErrorBoundaryRoute exact path="/dashboard" component={Dashboard} checkUser={true} />
                          <ErrorBoundaryRoute exact path="/portfolio" component={PortfolioPage} checkUser={true} />
                          <ErrorBoundaryRoute exact path="/reporting" component={ReportingPage} checkUser={true} />
                          <ErrorBoundaryRoute exact path="/review-page" component={ReviewPage} checkUser={true} />
                          <ErrorBoundaryRoute exact path="/share-diligence" component={ShareDiligence} checkUser={true} />
                          <ErrorBoundaryRoute exact path="/nft-report" component={NftReport} checkUser={true} />
                          <Route exact path="/error-page" component={PageNotFound} />
                          <Redirect from="*" to="/dashboard" />
                        </Switch>
                      }
                    </PageLayout>
                  }
                </AutoLoginComponent>
              {/* </InterceptorComponent> */}
            </BrowserRouter>
          }
          <Snackbar
            open={storeState.alertSettings.open}
            autoHideDuration={storeState.alertSettings.autoHideDuration}
            onClose={handleCloseMessage}
            anchorOrigin={{ vertical:'top', horizontal: 'right' }}
          >
            <Alert onClose={handleCloseMessage} severity={storeState.alertSettings.severity} 
              variant='filled'
              iconMapping={{ error: <ReportProblemOutlinedIcon />}}
            >
              {storeState.alertSettings.text}
            </Alert>
          </Snackbar>
        </StoreContext.Provider>
        : ''}
    </ThemeProvider>
  );
}

export default App;
