import { useEffect, useState } from "react";
import { BrandButton, SecondaryBrandButton, TempBrandButton, TempSecondaryBrandButton } from "../CoreComponents/BrandButton";
import { BrandMenuItem, BrandSelect } from "../CoreComponents/BrandSelect";
import { makeStyles } from '@material-ui/core/styles';
import { BrandInput } from "../CoreComponents/BrandInput";
import { IconButton, Switch, Typography } from "@material-ui/core";
import clsx from 'clsx';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { getData, putData } from '../utils/FetchUtils'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { BrandModal } from "../CoreComponents/BrandModal";
import { BrandLoader } from "../CoreComponents/BrandLoader";
import { BrandAlert } from "../CoreComponents/BrandAlert";
import { ButtonSize } from "../../constants/buttonConstants";

const useDataMappingStyles = makeStyles(theme => ({
    container: {
        width: theme.spacing(130),
        margin: '0 auto 0 auto'
    },
    validationAndMapping: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(4),
    },
    invalidInput: {
        "& .MuiInputBase-root": {
            borderColor: theme.palette.primary.alert
        }
    },
    alternativeNamesList: {
        height: theme.spacing(42.5),
        overflow: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    errorMessage: {
        color: theme.palette.primary.alert
    },
    selectColumn: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        "& .MuiInputBase-root": {
            width: theme.spacing(22.5)
        }
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        gap: 20,
        "& .MuiInputBase-root": {
            width: theme.spacing(43.75)
        },
        "& .MuiButtonBase-root": {
            width: theme.spacing(10),
            height: theme.spacing(5),
            color: 'black'
        }
    },
    vertical: {
        border: `1px solid ${theme.palette.primary.darkGrayNuance}`,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
    },
    validations: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'flex-start',
        gap: theme.spacing(1.5)
    },
    switch: {
        display: 'flex',
        alignItems: 'baseline'
    },
    switchAction: {
        margin: 'auto 0 auto 0',
        "& .MuiSwitch-root": {
            marginTop: 5
        }
    },
    switchLabel: {
        width: theme.spacing(20),
        margin: 'auto 0 auto 0',
    },
    validationsTitle: {
        marginBottom: theme.spacing(3),
    },
    saveMsg: {
        marginBottom: theme.spacing(8.5)
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2)
    },
    saveAndResetActions: {
        display: 'flex',
        marginTop: '20px',
        gap: theme.spacing(2),
        alignItems: 'baseline',
        '& button': {
            width: '183px',
        }
    },
    saveMessage: {
        display: 'flex',
        justifyContent: 'start',
        paddingTop: 20
    },
    saving: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

const DataMapping = () => {
    const styles = useDataMappingStyles();
    const [selectedColumn, setSelectedColumn] = useState('')
    const [invalidInputMessage, setInvalidInputMessage] = useState('');
    const [required, setRequired] = useState(false);
    const [dateFormat, setDateFormat] = useState(false);
    const [upcFormat, setUpcFormat] = useState(false);
    const [isrcFormat, setIsrcFormat] = useState(false);
    const [numbersOnly, setNumbersOnly] = useState(false);
    const [yearQuarter, setYearQuarter] = useState(false);
    const alternativeName = useState('');
    const [alternativeNames, setAlternativeNames] = useState([]);
    const [defaultSongData, setDefaultSongData] = useState({})
    const [saved, setSaved] = useState(true);
    const [notSavedModal, setNotSavedModal] = useState(false);
    const [reportingCompanies, setReportingCompanies] = useState([]);
    const [selectedReportingCompany, setSelectedReportingCompany] = useState('');
    const [mappings, setMappings] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedColumnData, setSelectedColumnData] = useState(undefined);

    const [tempColumnName, setTempColumnName] = useState('');
    const [tempReportingCompany, setTempReportingCompany] = useState('');

    const [finishedSavingAlert, setFinishedSaving] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + "/api/admin/mappings")
            .then(mappingsData => {
                setMappings(mappingsData);
                getData(process.env.REACT_APP_SERVER_HOST + "/api/admin/reporting-companies")
                    .then(reportingCompaniesData => {
                        setReportingCompanies(reportingCompaniesData.companies);
                        setSelectedReportingCompany(reportingCompaniesData.companies[0].id);
                    })
            });
        setSaved(true);
    }, [])

    useEffect(() => {
        if (!selectedReportingCompany && !mappings.length) {
            return;
        }
        const mappedColumns = [];
        for (const map of mappings) {
            if (map.reportingCompanyId === selectedReportingCompany) {
                mappedColumns.push(map);
            }
        }

        mappedColumns.sort((a, b) => a.name.localeCompare(b.name));
        setColumns(mappedColumns);
        setSelectedColumn(mappedColumns[0].name);
        setSaved(true);
    }, [selectedReportingCompany])


    useEffect(() => {
        setRequired(false);
        setDateFormat(false);
        setUpcFormat(false);
        setIsrcFormat(false);
        setNumbersOnly(false);
        setYearQuarter(false);

        if (columns.length && selectedReportingCompany && selectedColumn) {
            let columnData = undefined;
            for (const column of columns) {
                if (column.name === selectedColumn && column.reportingCompanyId === selectedReportingCompany) {
                    columnData = column;
                    break;
                }
            }

            setSelectedColumnData(columnData)
            setDefaultSongData(columnData);
            setAlternativeNames(columnData.alternativeNames)

            columnData.validations.split("|").forEach(v => {
                switch (v) {
                    case 'required':
                        setRequired(true);
                        break;
                    case 'date':
                        setDateFormat(true);
                        break;
                    case 'upc':
                        setUpcFormat(true);
                        break;
                    case 'isrc':
                        setIsrcFormat(true);
                        break;
                    case 'number':
                        setNumbersOnly(true);
                        break;
                    case 'yearQuarter':
                        setYearQuarter(true);
                        break;
                    default:
                        break;
                }
            })
        }
        setSaved(true);
    }, [columns, selectedColumn])

    function addMapping() {
        setIsSaving(true);

        selectedColumnData.alternativeNames = alternativeNames;
        const usedValidation = [];
        if (required) usedValidation.push('required');
        if (dateFormat) usedValidation.push('date');
        if (upcFormat) usedValidation.push('upc');
        if (isrcFormat) usedValidation.push('isrc');
        if (numbersOnly) usedValidation.push('number');
        if (yearQuarter) usedValidation.push('yearQuarter');
        selectedColumnData.validations = usedValidation.join('|')

        putData(process.env.REACT_APP_SERVER_HOST + "/api/admin/mappings", selectedColumnData)
            .then(data => {
                if (data.success) {
                    setSaved(true);
                }
                setIsSaving(false);
                setSavedSuccessfully(true);
                setFinishedSaving('Saved successfully');
            }, error => {
                setFinishedSaving('Failed to save, please try again');
                console.error(error);
            })
    }

    useEffect(() => {
        if (finishedSavingAlert) {
            setTimeout(() => {
                setFinishedSaving('');
            }, "4000")
        }
    }, [finishedSavingAlert])

    function resetToDefault() {
        setAlternativeNames(defaultSongData.alternativeNames);
        setRequired(false);
        setDateFormat(false);
        setUpcFormat(false);
        setIsrcFormat(false);
        setNumbersOnly(false);
        setYearQuarter(false);
        selectedColumnData.validations.split("|").forEach(v => {
            switch (v) {
                case 'required':
                    setRequired(true);
                    break;
                case 'date':
                    setDateFormat(true);
                    break;
                case 'upc':
                    setUpcFormat(true);
                    break;
                case 'isrc':
                    setIsrcFormat(true);
                    break;
                case 'number':
                    setNumbersOnly(true);
                    break;
                case 'yearQuarter':
                    setYearQuarter(true);
                    break;
                default:
                    break;
            }
        })
        setSaved(true);
    }

    function addAlternative(e) {
        e.preventDefault();
        if (alternativeName[0].length && !alternativeNames.includes(alternativeName[0])) {
            setAlternativeNames([...alternativeNames, alternativeName[0]]);
            alternativeName[1]('');
        } else {
            if (!alternativeName[0].length) {
                setInvalidInputMessage("Value must be not empty");
            } else {
                setInvalidInputMessage("Alternative name already exists");
            }
        }
        setSaved(false);
    }

    useEffect(() => {
        setInvalidInputMessage('');
    }, [alternativeName[0]])

    function onChangeColumnWithNotify(e) {
        if (!saved) {
            setNotSavedModal(true);
            setTempColumnName(e.target.value)
        } else {
            setSelectedColumn(e.target.value)
        }
        setInvalidInputMessage('')
    }

    function onChangeCompanyWithNotify(e) {
        if (!saved) {
            setNotSavedModal(true);
            setTempReportingCompany(e.target.value)
        } else {
            setSelectedReportingCompany(e.target.value)
        }
        setInvalidInputMessage('')
    }

    return (
        <div className={styles.container}>
            <BrandModal
                open={notSavedModal}
                onClose={() => setNotSavedModal(false)}
            >
                <Typography variant='h5' component='div' color='primary'>Save Changes</Typography>
                <div className={styles.saveMsg}>
                    Are you sure you want to leave without saving?
                </div>
                <div className={styles.actions}>
                    <TempSecondaryBrandButton variant='outlined' onClick={() => setNotSavedModal(false)}>No</TempSecondaryBrandButton>
                    <TempBrandButton onClick={() => {
                        if (tempColumnName) {
                            setSelectedColumn(tempColumnName);
                        }
                        if (tempReportingCompany) {
                            setSelectedReportingCompany(tempReportingCompany);
                        }
                        setTempColumnName('');
                        setTempReportingCompany('');
                        setNotSavedModal(false);
                        setSaved(true);
                    }}>Yes</TempBrandButton>
                </div>
            </BrandModal>
            <Typography
                variant="h5"
                style={{ paddingBottom: 20 }}
            >
                Data Mapping Settings
            </Typography>
            <div>
                <div className={styles.selectColumn}>
                    <div>Reporting Company</div>
                    <BrandSelect
                        label=""
                        value={selectedReportingCompany}
                        onChangeWithNotify={onChangeCompanyWithNotify}
                    >
                        {reportingCompanies.map((x) => {
                            return (<BrandMenuItem key={x.id} value={x.id}>
                                {x.name}
                            </BrandMenuItem>)
                        })}
                    </BrandSelect>
                </div>
                <div className={styles.selectColumn}>
                    <div>Column</div>
                    <BrandSelect
                        label=""
                        value={selectedColumn}
                        onChangeWithNotify={onChangeColumnWithNotify}
                    >
                        {columns.map((x) => {
                            return (<BrandMenuItem key={x.id} value={x.name}>
                                {x.name}
                            </BrandMenuItem>)
                        })}
                    </BrandSelect>
                </div>
                <div className={styles.validationAndMapping}>
                    <div>
                        {invalidInputMessage.length > 0 ?
                            <div className={styles.errorMessage}>{invalidInputMessage}</div>
                            :
                            null}
                        <div>Will be automatically mapped to the following column names:</div>
                        <List className={styles.alternativeNamesList}>
                            {alternativeNames.map(x => {
                                return (
                                    <ListItem key={x}>
                                        <ListItemIcon>
                                            <IconButton onClick={() => {
                                                setSaved(false);
                                                setAlternativeNames(alternativeNames.filter(e => e !== x))
                                            }}>
                                                <HighlightOffIcon />
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemText primary={x} />
                                    </ListItem>
                                );
                            })}
                        </List>

                        <form className={clsx(styles.input, invalidInputMessage.length > 0 ? styles.invalidInput : null)} onSubmit={addAlternative}>
                            <BrandInput
                                $value={alternativeName}
                                placeholder='Alternative name'
                            />
                            <TempBrandButton size={ButtonSize.SMALL} type='submit' >Add</TempBrandButton>
                        </form>
                    </div>
                    <hr className={styles.vertical} />
                    <div className={styles.validations}>
                        <div className={styles.validationsTitle}>Validation rules applied for this column:</div>
                        <div className={styles.switch}>
                            <div className={styles.switchLabel}>Required</div>
                            <div className={styles.switchAction}>
                                <Switch
                                    color="primary"
                                    checked={required}
                                    onChange={() => {
                                        setRequired(!required);
                                        setSaved(false);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.switch}>
                            <div className={styles.switchLabel}>Date Format</div>
                            <div className={styles.switchAction}>
                                <Switch
                                    color="primary"
                                    checked={dateFormat}
                                    onChange={() => {
                                        setDateFormat(!dateFormat);
                                        setSaved(false);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.switch}>
                            <div className={styles.switchLabel}>UPC format</div>
                            <div className={styles.switchAction}>
                                <Switch
                                    color="primary"
                                    checked={upcFormat}
                                    onChange={() => {
                                        setUpcFormat(!upcFormat);
                                        setSaved(false);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.switch}>
                            <div className={styles.switchLabel}>ISRC format</div>
                            <div className={styles.switchAction}>
                                <Switch
                                    color="primary"
                                    checked={isrcFormat}
                                    onChange={() => {
                                        setIsrcFormat(!isrcFormat);
                                        setSaved(false);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.switch}>
                            <div className={styles.switchLabel}>Numbers only</div>
                            <div className={styles.switchAction}>
                                <Switch
                                    color="primary"
                                    checked={numbersOnly}
                                    onChange={() => {
                                        setNumbersOnly(!numbersOnly);
                                        setSaved(false);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.switch}>
                            <div className={styles.switchLabel}>Year quarter format</div>
                            <div className={styles.switchAction}>
                                <Switch
                                    color="primary"
                                    checked={yearQuarter}
                                    onChange={() => {
                                        setYearQuarter(!yearQuarter)
                                        setSaved(false);
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.saveAndResetActions}>
                    <TempSecondaryBrandButton 
                        size={ButtonSize.SMALL}
                        onClick={resetToDefault} 
                        variant='outlined'>
                            Reset To Default
                    </TempSecondaryBrandButton>
                    <TempBrandButton
                        size={ButtonSize.SMALL}
                        onClick={addMapping}
                    >
                        {isSaving ?
                            <div className={styles.saving}>
                                <BrandLoader color='white' width={20} height={20} />
                                <div>Saving</div>
                            </div>
                            :
                            'Save Changes'
                        }
                    </TempBrandButton>
                </div>
                <div className={styles.saveMessage} >
                    {finishedSavingAlert ? <BrandAlert success={savedSuccessfully}>{finishedSavingAlert}</BrandAlert> : null}
                </div>
            </div>
        </div>
    );
}

export default DataMapping;